
	import { Component } from "vue-property-decorator";

	import TitleModal from "@/components/modal/TitleModal.vue";
	import Base from "../Base";
	import FilterModal, { toHistoryQuery } from "./modals/FilterModal.vue";
	import Bill from "./components/Bill.vue";

	import type { HistoryQuery } from "./modals/FilterModal.vue";
	import type { FundIndexModel } from "@/models/Fund";
	import type { BillModel } from "@common/models/fund";
	import type { TagModel } from "@common/models/kiosk";

	@Component({ components: { Bill, FilterModal, TitleModal } })
	export default class extends Base {
		$refs!: {
			modal: TitleModal,
			filter: FilterModal,
		};

		protected data: BillModel[] | null = null;
		protected query: HistoryQuery = {
			mode: "tag",
			tag: [],
			id: [],
			auditor: null,
		};
		protected tags: TagModel[] = [];

		beforeMount(): void {
			post<TagModel[]>('/api/tag').then(v => this.tags = v);
			this.load();
		}

		protected async showFilter(): Promise<void> {
			if(await this.$refs.filter.show()) await this.load();
		}

		private async load() {
			this.data = null;
			try {
				this.data = await post<BillModel[]>("/api/Fund/History", toHistoryQuery(this.query));
				this.update = new Date();
			} catch(e) {
				this.data = null;
				throw e;
			}
		}

		protected edit(k: FundIndexModel): void {
			var m = this.$refs.modal;
			m.model = k;
			m.show();
		}
	}
