

	import { Component, Prop, Vue } from "vue-property-decorator";
	import type { FundIndexModel } from "@/models/Fund";

	@Component
	export default class extends Vue {
		@Prop() public data!: FundIndexModel;

		protected get lastAuditTime(): string {
			return this.data.LastAuditTime?.second() ?? "無";
		}
	}
