var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject } from 'vue-property-decorator';
import Modal from "@/components/modal";
let default_1 = class default_1 extends Modal {
    constructor() {
        super(...arguments);
        this.model = null;
        this.query = null;
        this.check1 = false;
        this.check2 = false;
        this.check3 = true;
        this.data = null;
    }
    created() {
        post("/api/Info").then(v => this.data = v);
    }
    ok() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.check3)
                this.query.Comment = null;
            yield post('/api/fund/audit', Object.assign({}, this.query));
            this.close();
            yield this.alert('對帳完成', undefined, "success", "回到待對帳列表");
            this.$emit('done');
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
