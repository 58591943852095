var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component } from "vue-property-decorator";
import TitleModal from "@/components/modal/TitleModal.vue";
import Base from "../Base";
import FilterModal, { toFilterQuery } from "../report/modal/FilterModal.vue";
import Summary from "../report/components/Summary.vue";
import Kiosk from "./components/Kiosk.vue";
import AuditModal from "./modals/AuditModal.vue";
let default_1 = class extends Base {
    constructor() {
        super(...arguments);
        this.data = null;
        this.summary = [];
        this.query = {
            mode: "tag",
            tag: [],
            id: [],
            city: null,
            town: null,
        };
        this.tags = [];
    }
    get active() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.filter(d => d.IsActive)) !== null && _b !== void 0 ? _b : [];
    }
    beforeMount() {
        post('/api/tag').then(v => this.tags = v);
        this.load();
    }
    showFilter() {
        return __awaiter(this, void 0, void 0, function* () {
            if (yield this.$refs.filter.show())
                yield this.load();
        });
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.summary = [];
            try {
                this.data = yield post("/api/Fund", toFilterQuery(this.query));
                this.summary = this.data.map(d => d.Report);
                this.update = new Date();
            }
            catch (e) {
                this.data = null;
                throw e;
            }
        });
    }
    edit(k) {
        var m = this.$refs.modal;
        m.model = k;
        m.show();
    }
    audit(k) {
        var m = this.$refs.audit;
        m.model = k;
        m.query = {
            Id: k.Id,
            Comment: null,
            Payload: {
                Coin: k.Report.Coins,
                Leapa: k.Report.Leapa,
            },
        };
        m.show();
    }
};
default_1 = __decorate([
    Component({ components: { Kiosk, Summary, FilterModal, TitleModal, AuditModal } })
], default_1);
export default default_1;
