var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as bootstrap from 'bootstrap';
import { Component, Prop, Vue } from 'vue-property-decorator';
export function toHistoryQuery(query, options = {}) {
    if (query.mode == 'id')
        options.Ids = query.id;
    else
        options.Tags = query.tag;
    options.AuditorId = query.auditor;
    return options;
}
export function toIndexQuery(query) {
    let options = {
        Start: query.start.toISO(),
        End: query.end.toISO(),
    };
    return toHistoryQuery(query, options);
}
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.value = false;
        this.options = [];
        this.auditorOptions = null;
        this.temp = this.query;
    }
    created() {
        post("/api/Fund/AuditorOptions").then(v => this.auditorOptions = v);
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el);
        post("/api/device").then(v => this.options = v);
    }
    show() {
        this.value = false;
        return new Promise(resolve => {
            this.copy();
            this.modal.show();
            this.$el.addEventListener('hidden.bs.modal', () => resolve(this.value), { once: true });
        });
    }
    get unselected() {
        return this.options.filter(k => !this.temp.id.includes(k.Kiosk.Id));
    }
    onRange(e) {
        if (e.length < 2)
            return;
        if (!e[1])
            e[1] = e[0];
        [this.temp.start, this.temp.end] = e;
    }
    onOption(event) {
        let sel = event.target;
        let code = sel.value;
        this.temp.id.push(code);
        sel.value = "";
    }
    copy() {
        Vue.set(this, "temp", {
            start: this.query.start,
            end: this.query.end,
            mode: this.query.mode,
            init: this.query.init,
            tag: this.query.tag.concat(),
            id: this.query.id.concat(),
            auditor: this.query.auditor,
        });
        this.$refs.range.reset(this.query.init);
    }
    getName(k) {
        return k.Name || k.Kiosk.Name || "未命名";
    }
    getKiosk(c) {
        let k = this.options.find(e => e.Kiosk.Id == c);
        if (!k)
            return "";
        return this.getName(k);
    }
    remove(c) {
        this.temp.id.splice(this.temp.id.indexOf(c), 1);
    }
    ok() {
        Object.assign(this.query, this.temp);
        this.query.init = this.$refs.range.active;
        this.value = true;
    }
    reset() {
        this.temp.start = new Date();
        this.temp.end = new Date();
        this.temp.mode = "tag";
        this.temp.tag = [];
        this.temp.id = [];
        this.temp.auditor = null;
        this.$refs.range.reset(this.temp.init = 0);
    }
    btnClass(o) {
        return this.temp.tag.includes(o.Id) ? "btn-secondary" : "btn-light";
    }
    toggle(o) {
        let t = o.Id, i = this.temp.tag.indexOf(t);
        if (i >= 0)
            this.temp.tag.splice(i, 1);
        else
            this.temp.tag.push(t);
    }
};
__decorate([
    Prop()
], default_1.prototype, "tags", void 0);
__decorate([
    Prop()
], default_1.prototype, "query", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
