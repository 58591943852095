
export interface BillModel {
	Id: string;
	Serial: string;
	Status: BillStatus;
	AuditStatus: AuditStatus;
	ExpectedAmount: number;
	AuditedAmount: number;
	CreateTime: Date;
	StartTime: Date | null;
	EndTime: Date | null;
	Comment: string;
	PaymentTypeName: string;
	AuditorName: string;
	KioskName: string;
	HardwareCode: string;
	Payload: string | null;
}

export enum BillStatus {
	Pending = 0,
	Done = 1,
}

export enum AuditStatus {
	NotApplicable = -1,
	Unaudited = 0,
	Even = 1,
	Fixed = 2,
	Uneven = 3,
}
